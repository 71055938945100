<div class="collection-collapse-block border-0" [class.open]="collapse">

  <h3 class="collapse-block-title" (click)="collapse = !collapse" >Category</h3>
  <hr *ngIf="catname!=''" >
  <label *ngIf="catname!=''">Filter By : ({{ catname }})</label>
      <hr *ngIf="catname!=''">
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      
      <ul class="category-list">
        <li>
          <a [routerLink]="['/shop/placeorder']"
             [queryParams]="{ category: ''}">
            {{ capitalizeName('All') }}
          </a>
        </li>
        <li *ngFor="let category of filterbyCategory">
          <a [routerLink]="['/shop/placeorder']"
             [queryParams]="{ category: category.name }">
            {{ capitalizeName(category.name) }} ({{ category.count }})
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- End Categories List -->    

<!-- Start Categories List -->
<!-- <div class="collection-collapse-block border-0" [class.open]="collapse"> -->
    <!-- <h3 class="collapse-block-title" >Category</h3> -->
    <!-- <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <owl-carousel-o [options]="LogoSliderConfig" class="no-arrow">
                    <ng-container *ngFor="let category of filterbyCategory">
                      <ng-template carouselSlide>
                        <div>
                          <div class="logo-block">
                           
                            <a [routerLink]="['/shop/placeorder']"
                                    [queryParams]="{ category: category}">
                            <img [src]="ImageSrc+category+'.jpg'" alt="logo" class="catimg" >
                              
                                        {{ category }}
                                    </a>
                            
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o> -->
                <!-- <li *ngFor="let category of filterbyCategory">
                    <a [routerLink]="['/shop/placeorder']"
                       [queryParams]="{ category: category}">
                        {{ category }}
                    </a>
                </li> -->
            <!-- </ul>
        </div>
    </div>
</div> -->
<!-- End Categories List -->    


