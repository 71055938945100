<!--footer section -->
<footer [class]="class">
	<!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From  By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="logoPath" alt="logo" width="230">
						</div>
						
						 <!-- <div class="footer-social">
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div> -->
					</div>
				</div>
				 <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Navigation</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
								<li><a  [routerLink]="['/shop/placeorder']">Place Order</a></li>
    							<li><a  [routerLink]="['/pages/orderHistory']">Order/Invoice History</a></li>
      							<li><a  [routerLink]="['/pages/purchaseTrends']">Purchase Trends</a></li>
      							<li><a  [routerLink]="['/shop/collection/newlaunch']">New Launches</a></li>
      							<li><a  [routerLink]="['/pages/promotions']">Promotions</a></li>
      							<li><a  [routerLink]="['/shop/productspecification']">Product Specifications</a></li>
                            </ul>
                        </div>
					</div>
				</div> 
				
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Contact Us</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						         <li><i class="fa fa-map-marker"></i>{{settings.address}}</li> 
						        <li><i class="fa fa-phone"></i>Call Us: {{settings.mobilePhone}}</li>
						        <li style="text-transform:none;"><i class="fa fa-envelope-o" ></i>Email Us: <a>{{settings.email}}</a></li>
						        <li><i class="fa fa-facebook"></i><a href="https://www.facebook.com/AlSafaFood/" target="_blank"> Facebook</a></li>
								<li><i class="fa fa-instagram"></i><a href="https://www.instagram.com/alsafafoods/?hl=en" target="_blank"> Instagram</a></li> 
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                  <div class="footer-end text-center">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} x2x Ecommerce | Version 1.0.0.15</p>
                    
                  </div>
                </div>
				
              
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->