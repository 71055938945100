import { Component } from "@angular/core";
import { GlobalobjectsService } from "src/app/globalobjects.service";
import { Router } from "@angular/router";
import { LoginService } from "../loginc/login.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent {
  cname: any;
  selectedCustomerName: any;
  logoPath: string = "assets/images/placeholder-logo-2.png";
  IsSalesRep: boolean = false;
  IsMobile: boolean = false;
  constructor(
    private GlobalObject: GlobalobjectsService,
    private loginService: LoginService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.IsMobile = window.innerWidth <= 768;
    this.getCreditLimit();
    if (localStorage.getItem("isSalesRep") == "true") {
      this.IsSalesRep = true;
      console.log(
        "🚀 > LandingComponent > ngOnInit > this.IsSalesRep:",
        this.IsSalesRep
      );
    }

    const settingString = localStorage.getItem("CompanyDetails") as string;

    console.log(
      "🚀 > LandingComponent > ngOnInit > settingString:",
      settingString
    );
    if (settingString != "undefined" && settingString != null) {
      let setting = JSON.parse(
        localStorage.getItem("CompanyDetails") as string
      );

      console.log("🚀 > LandingComponent > ngOnInit > setting:", setting);
      this.logoPath = this.GlobalObject.COMPANYIMAGEURL + setting.picture;
    }
    console.log(this.cname);

    if (localStorage.getItem("isLoggedIn") !== "true") {
      this.router.navigate(["/company"]);
    }
    this.cname = this.capitalizeName(localStorage.getItem("cname"));
    this.selectedCustomerName = this.capitalizeName(
      localStorage.getItem("selectedCustomerName")
    );
  }

  //

  capitalizeName(name: string): string {
    return name?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  credit:any ;
  balance:any; 
  getCreditLimit() {
    const data = {
      countryList_Id: localStorage.getItem("country"),
      customerNumber: localStorage.getItem("customerNumber"),
    };

    this.loginService.getCustomerCredit(data).subscribe((response: any) => {
      this.credit = response.data.value[0].creditLimitLCY;
      this.balance = response.data.value[0].balanceLCY;
    });
  }

  logout() {
    const setting = localStorage.getItem("setting");

    localStorage.clear();
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
    localStorage.removeItem("products");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("customercredit");
    localStorage.removeItem("customerbalance");
    localStorage.removeItem("customername");
    localStorage.removeItem("country");
    if (setting != null) {
      localStorage.setItem("setting", setting);
    }
    // this.router.navigate(['/company']);
    //.location.reload();
    this.router.navigate(["/company"]).then(() => {
      window.location.reload();
    });
  }
}
