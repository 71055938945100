import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-searchableselect',
  templateUrl: './searchableselect.component.html',
  styleUrls: ['./searchableselect.component.scss']
})
export class SearchableselectComponent {

  @Input() options: any[] = [];
  @Output() selectedOptionChange = new EventEmitter<any>();

  selectedOption: any;

  onOptionSelected(option: any): void {
    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
  }
}
