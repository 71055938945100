<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Amaranth:wght@700&display=swap" rel="stylesheet">
<div class="container-fluid bg mobileTopMargin">
    <nav class="navbar navbar-expand-md navbar-light custom-navbar p-4">
        <div class="d-flex flex-column flex-md-row align-items-center w-100">
            <div class="welcome p-1 order-md-1 " >
                <div class="p-3" style="border-radius: 20px; background-color:  rgba(255, 255, 255, 0.5);; display: inline-block;">
                <h4 class=" welcome-text text-dark">Welcome: <b><span class="capitalize">{{cname}}</span></b></h4>
                <h4 *ngIf="IsSalesRep" class="welcome-text text-dark">Selected Customer: <span class="capitalize">{{selectedCustomerName}}</span></h4>
                <h4 *ngIf="!IsMobile" class="welcome-text text-dark">Credit Limit: <span class="capitalize">{{ credit | currency }}</span></h4>
                <a [routerLink]="['/pages/orderHistory']" [queryParams]="{ tab: 3 }"><h4  *ngIf="!IsMobile" class="welcome-text text-dark">Balance Outstanding: <span class="capitalize">{{ balance | currency }}</span></h4></a>
                </div>
            </div>
            
            <a class="navbar-brand order-1 order-md-2 mx-auto" href="#">
                <img [src]="logoPath" width="230" alt="Logo">
            </a>
             
            <div class="navbar-nav p-2 order-3 order-md-3 ">
                <app-dropdown></app-dropdown>
            </div>
        </div>
    </nav>
</div>

      
<!-- 
    <div class="info-box">
        <p ><span class="mheading"> Al Safa Foods<br>
            The Name<br>
            You Can Trust</span></p>
    </div> -->

    <!-- <a class="navbar-brand" href="#">
        <img src="https://alsafahalal.com/wp-content/uploads/2018/07/head-logo-2.png" width="200" alt="Logo"> 
    </a>
    <div class="navbar-nav">
        
<a class="nav-item nav-link" href="#">Place Order</a>
<a class="nav-item nav-link" href="#">Order/Invoice History</a>
<a class="nav-item nav-link" href="#">New Launches</a>
<a class="nav-item nav-link" href="#">Promotions</a>
<a class="nav-item nav-link" href="#">Product Specifications</a> -->

<!-- 
<p style="font-size: 30px; color: black;">Welcome to Al Safa</p> -->
<!-- 
<section class="ratio2_1">
    <div class="container">
        <div class="row partition4">
            <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/shop/placeorder']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/B1.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>Place order</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/pages/orderHistory']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/B2.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>order and <br> invoice history</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/shop/placeorder']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/B3.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>new launches</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
          
        </div>
    </div>
</section> -->




<!-- <section class="ratio2_1">
    <div class="container">
        <div class="row partition4">
            <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/shop/placeorder']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/B4.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>promotions</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/shop/productspecification']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/B5.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>Product Specifications</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
             <div class="col-lg-4 col-md-4">
                <a [routerLink]="['/shop/placeorder']" >
                    <div class="collection-banner p-left">
                        <div class="img-part">
                            <img src="assets/images/collection/watch/1.jpg" class="img-fluid">
                        </div>
                        <div class="contain-banner banner-4">
                            <div>
                                <h2>new launches</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div> 
          
        </div>
    </div>
</section> -->
