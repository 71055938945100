import { Component } from '@angular/core';
import {LoginService} from '../loginc/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {GlobalobjectsService} from '../../globalobjects.service';

@Component({
  selector: 'app-setpass',
  templateUrl: './setpass.component.html',
  styleUrls: ['./setpass.component.scss']
})
export class SetpassComponent {

  userPassword: string;
  confirmPassword: string;
  constructor(private toastrService: ToastrService,private route:Router, public Loginservice:LoginService,public Globalobjects:GlobalobjectsService) { }


 

  onSubmit() {

    let data = {
      "country": this.Globalobjects.countryName,
      "customerNumber": this.Globalobjects.customerData.Number,
      "password": this.userPassword,
      "customerName": this.Globalobjects.customerData.DisplayName,
      "email": this.Globalobjects.customerData.Email,
      "phoneNumber": this.Globalobjects.customerData.PhoneNumber,
      "mobilePhoneNO": this.Globalobjects.customerData.MobilePhoneNo,
      "addressLine1": this.Globalobjects.customerData.AddressLine1,
      "addressLine2": this.Globalobjects.customerData.AddressLine2,
      "city": this.Globalobjects.customerData.City
    }
    this.Loginservice.SaveCustomer(data).subscribe((response:any) => {
      console.log(response);
      if(response.apiStatus.isSuccess){

        this.toastrService.success('Verification email sent to your email address.');
        this.route.navigate(['/login']);
      }
      else{
        alert("Invalid Customer Number");
      }
    });
  }
}
