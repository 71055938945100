<app-breadcrumb [title]="'Elements'" [breadcrumb]="'Product Slider'"></app-breadcrumb>
<!-- Product slider start-->
<div class="title1  section-t-space">
    <h4>special offer</h4>
    <h2 class="title-inner1">top collection</h2>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="product-para">
          <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s,</p>
        </div>
      </div>
    </div>
  </div>
  <section class="section-b-space p-t-0">
    <div class="container">
      <div class="row">
        <div class="col">
          <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
            <ng-container *ngFor="let product of products | slice:0:16">
              <ng-template carouselSlide>
                <div class="product-box">
                  <app-product-box-one 
                    [product]="product" 
                    [currency]="productService?.Currency"
                    [thumbnail]="true"
                    [cartModal]="true">
                  </app-product-box-one>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Product slider End -->