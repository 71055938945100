<section class="p-0">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>500</h1>
                    <h2>The page you are looking for is temporarily unavailable.</h2>
                    <!-- <a [routerLink]="'/shop/collection/no/sidebar'" class="btn btn-solid">back to home</a> -->
                </div>
            </div>
        </div>
    </div>
</section>