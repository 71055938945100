<div class="dropdown" (click)="toggleDropdown($event)">
    <button class="btn btn-light dropdown-toggle btnstyle" style="text-transform: capitalize; font-size: 20px;" type="button">
     Menu
    </button>
    <div class="dropdown-menu" [class.show]="isDropdownOpen">
      <a class="dropdown-item" *ngIf="IsSalesRep" [routerLink]="['/select-customer']">Select Customer</a>
      <a class="dropdown-item" [routerLink]="['/shop/placeorder']">Place Order</a>
      <a class="dropdown-item" [routerLink]="['/pages/orderHistory']">Order/Invoice History</a>
      <a class="dropdown-item" [routerLink]="['/pages/purchaseTrends']">Purchase Trends</a>
      <a class="dropdown-item" [routerLink]="['/shop/collection/newlaunch']">New Launches</a>
      <a class="dropdown-item" [routerLink]="['/pages/promotions']">Promotions</a>
      <a class="dropdown-item" [routerLink]="['/shop/productspecification']">Product Specifications</a>
      <a class="dropdown-item" (click)="logout()" style="cursor: pointer;">Log Out</a>
    </div>
  </div>
  