import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { LogoSlider } from '../../../shared/data/slider';
import { GlobalobjectsService } from 'src/app/globalobjects.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public LogoSliderConfig: any = LogoSlider;
  ImageSrc:any;
  catname:any = '';
  constructor(public productService: ProductService, public Globalobjects:GlobalobjectsService ,private route: ActivatedRoute) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.ImageSrc = this.Globalobjects.ImageURL;

    this.route.queryParams.subscribe(params => {
      this.catname = params['category'] || '';
    });
  }
  capitalizeName(name: string): string {
    return name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  }

  
  get filterbyCategory() {
    // Create a map to count the products in each category
    const categoryCountMap = this.products.reduce((acc, product) => {
      acc[product.type] = (acc[product.type] || 0) + 1;
      return acc;
    }, {});
  
    // Convert the map to an array of objects with category name and count
    const categoryCountArray = Object.keys(categoryCountMap).map(category => ({
      name: category,
      count: categoryCountMap[category]
    }));
  
    return categoryCountArray;
  }
  

}
