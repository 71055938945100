import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalobjectsService } from 'src/app/globalobjects.service';
import { LoginService } from 'src/app/mylogin/loginc/login.service';
import { ProductService } from '../../services/product.service';
@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = true; // Default false
  
  public stick: boolean = true;
  cname:any = localStorage.getItem('cname');
  credit:any ;
  balance:any; 
  logoPath: string="assets/images/placeholder-logo-2.png";

  constructor(private router:Router, private GlobalObject:GlobalobjectsService,private loginService:LoginService , public ProductService: ProductService) { }


  logout(){
    const setting =localStorage.getItem('setting');
    localStorage.clear();
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    localStorage.removeItem('products')
    localStorage.removeItem('cartItems')
    localStorage.removeItem('customercredit')
    localStorage.removeItem('customerbalance')
    localStorage.removeItem('customername')
    localStorage.removeItem('country')

    if(setting != null){
      localStorage.setItem('setting', setting);
    }
    
   // this.router.navigate(['/company']);
   //.location.reload();
   this.router.navigate(['/company']).then(() => {
    window.location.reload();
});
  }
  promotions : any;
  promotionDescription: string[] = [];
  IsSalesRep: boolean = false;
  ngOnInit(): void {
    if(localStorage.getItem("isSalesRep") == 'true'){
      this.IsSalesRep = true;
    }
   const settingString = localStorage.getItem('CompanyDetails') as string;
    if( settingString != 'undefined' && settingString != null){
      let setting = JSON.parse(localStorage.getItem('CompanyDetails') as string);
      this.logoPath = this.GlobalObject.COMPANYIMAGEURL + setting.picture;
    } 
    this.getCreditLimit();
    this.getlist();
    
    this.promotions = JSON.parse(localStorage.getItem('promotiondata')  );
    //console.log(this.promotions);
    //const currentDate = new Date();
    
    this.promotionDescription = this.promotions.filter((item:any) => item.isActive == true ).map(promotion =>  promotion.promotionDescription);
  }
  WishlistCount: number = 0;
  getlist()
  {
    const loginresponse = JSON.parse(localStorage['loginresponse']);
    console.log("🚀 > WishlistComponent > loginresponse:", loginresponse)
    this.loginService.getwishlistdata(loginresponse.customerInfo.customer_Id).subscribe((response: any) => {
      console.log("++++++++++++ Wishlist ++++++++++++");
      console.log(response.data.length);
      this.ProductService.WishlistItemsCount = response.data.length;
      

    });
  }

  getCreditLimit() {

    const data = { 

      "countryList_Id": localStorage.getItem('country'),
      "customerNumber": localStorage.getItem('customerNumber')
      }
     
      this.loginService.getCustomerCredit(data).subscribe((response: any) => {      
      this.credit = response.data.value[0].creditLimitLCY;
      this.balance = response.data.value[0].balanceLCY;

     
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
