import { Component, PLATFORM_ID, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { LoginService } from "./mylogin/loginc/login.service";
import { TopBackBarComponent } from "./shared/components/top-back-bar/top-back-bar.component";
@Component({
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,
    private titleService : Title,
    private LoginService: LoginService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }
    this.isMobile = window.innerWidth <= 768; // Simple mobile detection
  // Listen to resize events
  window.addEventListener('resize', () => {
    this.isMobile = window.innerWidth <= 768;
  });
  }
  @ViewChild('mobileTopBar', { read: ElementRef }) mobileTopBar: ElementRef;
  height :string;
  isMobile: boolean;
  
  ngOnInit(): void {
    // console.log("🚀 AppComponent > GetSetting:")
    // this.LoginService.GetSetting().subscribe((data: any) => {
    //   console.log("🚀 Setting > data:", data.data)
    //   if (data) {
    //     localStorage.setItem('setting', JSON.stringify(data.data[0]));
    //     this.titleService.setTitle((data.data[0]?.title)? data.data[0].title : 'Al Safa - Halal Food'); 
    //   }
    // });

  
    
    const settingString = localStorage.getItem('setting') as string;
    if( settingString != 'undefined' && settingString != null){
      let setting = JSON.parse(localStorage.getItem('setting') as string );
      this.titleService.setTitle( setting.title? setting.title : 'Al Safa - Halal Food'); 

    }
}

  ngAfterViewInit() {
  this.height = this.mobileTopBar.nativeElement.firstChild.offsetHeight + 'px';

  console.log("🚀 > AppComponent > ngAfterViewInit > this.height:", this.height)

  console.log("🚀 > AppComponent > ngAfterViewInit > this.mobileTopBar:", this.mobileTopBar)

}
}
