import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {GlobalobjectsService} from '../../globalobjects.service';
import {LoginService} from '../loginc/login.service';
import { ToastrService } from 'ngx-toastr';
import { response } from 'express';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

  constructor(private toastrService: ToastrService,private route:Router , public Loginservice:LoginService , public Globalobjects:GlobalobjectsService) { }


  userName: any;
  userPass: string;
  countryId: string;
  msg: string; 
  loginError: string;
  companylist: any;
  logoPath: string;

  ngOnInit(): void {
   const settingString = localStorage.getItem('setting') as string;
    if( settingString != 'undefined' && settingString != null){
      let setting = JSON.parse(localStorage.getItem('setting') as string);
      this.logoPath = this.Globalobjects.BUSINESSINFOIMAGEURL + setting.logo;
    } else {
      this.logoPath = "assets/images/placeholder-logo-2.png";
    }

    if(localStorage.getItem('isLoggedIn') == "true"){
      this.route.navigate(['/index']);
    }

    this.getcompanylist();

}

getcompanylist() {
  this.Loginservice.getCompanyList().subscribe(response => {
    console.log(response.data);
    this.companylist = response.data;

  });
}
GetCompanyDetails(id:any) {
  this.Loginservice.getCompanyInformation(id).subscribe(response => {
    console.log("🚀 > CompanyComponent > this.Loginservice.getCompanyInformation > response:", response)
    localStorage.setItem('CompanyDetails', JSON.stringify(response.data[0]));

  });
}
  

  selectCountry(country:any) {
    
      
    this.countryId = country;
  }
  
  onSubmit() {
    // Access the values of userName and userPass here

    console.log('Country Name: '+ this.countryId)

    if(this.countryId == null){

      alert("Please Select Company");
    }
    else{
      this.GetCompanyDetails(this.countryId);
      localStorage.setItem('country',this.countryId);
      this.Globalobjects.countryName= this.countryId;
      this.route.navigate(['/login']);
    

  }
}
}
