<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body modal7">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="modal-bg">
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div>
                <div class="table-responsive">
                  <div class="custom-datatable">
                    <!-- <form>
                        <div class="mb-3">
                          <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText"
                            autocomplete="off" />
                        </div>
                      </form> -->
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col" sortable="no">Item Number</th>
                          <th scope="col" sortable="no">Item Description</th>
                          <th scope="col" sortable="no" class="text-end">
                            Quantity
                          </th>
                          <th scope="col" sortable="no" class="text-end">
                            Unit Price
                          </th>
                          <th scope="col" sortable="no" class="text-end">
                            Discount
                          </th>
                          <th scope="col" sortable="no" class="text-end">
                            Net Amount
                          </th>
                          <!-- <th
                          align="center"
                          scope="col"
                          sortable="amountIncludingVAT"
                          
                        >
                          Total Amount
                        </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let line of LineItems">
                          <th scope="row">
                            {{ line.lineObjectNumber }}
                          </th>
                          <td scope="row">{{ line.description }}</td>
                          <td align="right">{{ line.quantity }}</td>
                          <td align="right">{{ line.unitPrice | currency }}</td>
                          <td align="right">
                            {{ line.discountAmount | currency }}
                          </td>
                          <td align="right">
                            {{ line.netAmount | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
