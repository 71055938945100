import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import  {LogincComponent} from './mylogin/loginc/loginc.component';
import { OrderhistoryComponent } from './mylogin/orderhistory/orderhistory.component';
import { RegisterComponent } from './mylogin/register/register.component';
import { SetpassComponent } from './mylogin/setpass/setpass.component';
import { CompanyComponent } from './mylogin/company/company.component';
import { LandingComponent } from './mylogin/landing/landing.component';
import { SelectCustomerComponent } from './mylogin/select-customer/select-customer.component';
import { Pag500Component } from './mylogin/pag500/pag500.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'company',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'select-customer',
    redirectTo: 'select-customer',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
  },
  { 
      path: 'login', 
      component: LogincComponent,
      loadChildren: () => import('./mylogin/mylogin.module').then(m => m.MyloginModule) 
  },
  { 
      path: 'select-customer', 
      component: SelectCustomerComponent,
      loadChildren: () => import('./mylogin/mylogin.module').then(m => m.MyloginModule) 
  },
  { 
      path: '500', 
      component: Pag500Component,
  },
      { 
        path: 'OrderHistory', 
        component: OrderhistoryComponent,
        loadChildren: () => import('./mylogin/mylogin.module').then(m => m.MyloginModule) },
        {
          path: 'register',
          component: RegisterComponent,
        },
        {
          path: 'company',
          component: CompanyComponent,
        },
        {
          path: 'index',
          component: LandingComponent,
        },
        {
          path: 'SetPassword',
          component: SetpassComponent,
        },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '/login',
  },
 
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
