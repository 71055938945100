<section class="login-page section-b-space d-flex align-items-center mt-3">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">

                <div class="text-center">
                    <img alt="logo" class="img-fluid" src="assets/images/icon/logo.png" width="250" height="200">
                </div>
                <div class="theme-card">
                    <form class="theme-form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <div class="form-group">
                                <label for="country">Select a country:</label><br>
                                <img src="assets/images/flags/usa.png" alt="USA flag" width="50" height="40" class="flag" (click)="selectCountry('USA')">
                                <img src="assets/images/flags/canada.png" alt="Canada flag" width="50" height="40" class="flag ms-3" (click)="selectCountry('Canada')">

                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label for="email">Customer Number</label>
                            <input type="text" class="form-control" id="email" placeholder="Customer Number"
                            [(ngModel)]="Globalobjects.customerNumber" name="cnumber" required>
                        </div>
                       
                   
                        <button class="btn btn-solid" type="submit">Next</button><br><br>
                        <p> Already account? <a href="/login"> Login </a>
                     
                    </form>

                </div>
            </div>
        </div>
    </div>
</section>