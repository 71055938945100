<app-breadcrumb [title]="'Elements'" [breadcrumb]="'Banners'"></app-breadcrumb>

<!-- Parallax banner start-->
<section class="p-0 section-b-space">
  <div class="full-banner parallax-banner1 parallax text-center p-left"
    [ngStyle]="{'background-image': 'url(assets/images/parallax/1.jpg)'}">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h2>2018</h2>
            <h3>fashion trends</h3>
            <h4>special offer</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Parallax banner End -->

<!-- timer banner -->
<section class="section-b-space">
    <div class="container">
        <div class="row banner-timer" 
            [ngStyle]="{'background-image': 'url(assets/images/offer-banner-2.jpg)'}">
            <div class="col-md-6">
                <div class="banner-text">
                    <h2>Save <span>30% off</span> Digital Watch</h2>
                </div>
            </div>
            <div class="col-md-6">
                <div class="timer-box">
                    <div class="timer">
                        <p id="demo">
                            <span>11<span class='padding-l'>:</span><span class='timer-cal'>Days</span></span>
                            <span>11<span class='padding-l'>:</span><span class='timer-cal'>Hrs</span></span>
                            <span>11<span class='padding-l'>:</span><span class='timer-cal'>Min</span></span>
                            <span>11<span class='timer-cal'>Sec</span></span> 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <!-- timer banner end -->