import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  public url : any; 

  constructor(private router: Router) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }

  @ViewChild('TopHeader', { read: ElementRef }) TopHeader: ElementRef;
  height :string;

  ngAfterViewInit() {
    this.height = this.TopHeader.nativeElement.firstChild.offsetHeight+40 + 'px';
  }
  ngOnInit(): void {
  }

}
