import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, startWith, delay } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { GlobalobjectsService } from "src/app/globalobjects.service";
import { BehaviorSubject } from "rxjs";
import { AnyARecord } from "dns";
import { Console } from "console";
import { LoginService } from "src/app/mylogin/loginc/login.service";

const state = {
  products: localStorage.getItem("products") || "[]",
  wishlist: JSON.parse(localStorage.getItem("wishlistItems") || "[]"),
  compare: JSON.parse(localStorage.getItem("compareItems") || "[]"),
  cart: JSON.parse(localStorage.getItem("cartItems") || "[]"),
  cartPI: JSON.parse(localStorage.getItem("cartItemsPI") || "[]"),
  MissingPromoItems: JSON.parse(localStorage.getItem("MissingPromoItems") || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  userinfo: any;
  public WishlistItemsCount = 0;

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private loginService: LoginService,
    public Globalobjects: GlobalobjectsService
  ) {
    this.initializeCart();
  }

 
  private get products(): Observable<Product[]> {
    this.userinfo = localStorage.getItem("user");

    // Assuming you have data in this.GlobalObjects.productdata
    const newDataString = localStorage.getItem("rproducts");

    // Parse the JSON string into an array of Product objects
    const newData: Product[] = JSON.parse(newDataString);

    // Store the JSON string in localStorage
    localStorage["products"] = localStorage.getItem("rproducts");

    // Now, you can use localStorage['products'] to retrieve the data

    // Assuming you still want to return an observable, you can create an observable from the newData array

    return of(newData);
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(
      map((items) => {
        return items.find((item: any) => {
          return item.title.replace(" ", "-") === slug;
        });
      })
    );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find((item) => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
      });
    }
    this.toastrService.success("Product Has Been Added to Wishlist.", "", {
      timeOut: 1000,
    });
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    this.WishlistItemsCount = state.wishlist.length;
    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastrService.success(
      "Product Has Been Added to Compare.",
      "Success",
      { timeOut: 1000 }
    );
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }



  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  private cartItemsSubject: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  public get cartItems(): Observable<Product[]> {
    return this.cartItemsSubject.asObservable();
  }
  public updateCartItems(newCartItems: Product[]): void {
    this.cartItemsSubject.next(newCartItems);
    
  }
  private initializeCart(): void {
    const savedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
    this.cartItemsSubject.next(savedCartItems);
  }

  // public get cartItems(): Observable<Product[]> {
  //   const itemsStream = new Observable((observer) => {
  //     observer.next(state.cart);
  //     observer.complete();
  //   });
  //   return <Observable<Product[]>>itemsStream;
  // }

  public get cartItemsPI(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.cartPI);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  private missingPromoItemsSubject: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  
  // Public getter for the observable
  public get MissingPromoProducts(): Observable<Product[]> {
    return this.missingPromoItemsSubject.asObservable();
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find((item) => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false;

    if (cartItem) {
      cartItem.quantity += qty;
    } else {
      state.cart.push({
        ...product,
        quantity: qty,
      });
    }
    this.updateCartItems(state.cart);
   // this.getCustomerLastOrder();
   this.checkmissingpromoitems();
    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    this.promotionswork("From Add to Cart");

    this.toastrService.success("Product Has Been Added to Cart.", "", {
      timeOut: 1000,
    });

    return true;
  }

  public addToCartC(product, qtyc): any {
    const cartItem = state.cart.find((item) => item.id === product.id);
    const qty = product.quantity ? product.quantity : qtyc;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false;

    if (cartItem) {
      cartItem.quantity += qty;
    } else {
      state.cart.push({
        ...product,
        quantity: qty,
      });
    }
    this.updateCartItems(state.cart);
   // this.getCustomerLastOrder();
    this.checkmissingpromoitems();
    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    this.promotionswork("From Add to Cart C");
    this.toastrService.success("Product Has Been Added to Cart.", "", {
      timeOut: 1000,
    });
    return true;
  }

  public addToCartPI(product, qtyc): any {
    const cartItem = state.cartPI.find((item) => item.id === product.id);
    const qty = product.quantity ? product.quantity : qtyc;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false;

    if (cartItem) {
      cartItem.quantity += qty;
    } else {
      state.cartPI.push({
        ...product,
        quantity: qty,
      });
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItemsPI", JSON.stringify(state.cartPI));

    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(
    product: Product,
    quantity: number
  ): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty;
        }
       // this.getCustomerLastOrder();
    this.checkmissingpromoitems();
    this.updateCartItems(state.cart);
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        this.promotionswork("From Update Quantity");
        return true;
      }
    });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In Inventory " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
   // this.getCustomerLastOrder();
     this.checkmissingpromoitems();
     this.updateCartItems(state.cart);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    this.promotionswork("From Remove Item");
    return true;
  }

  public removeCartItemPI(product: Product): any {
    const index = state.cartPI.indexOf(product);
    state.cartPI.splice(index, 1);
    localStorage.setItem("cartItemsPI", JSON.stringify(state.cartPI));

    return true;
  }

  public removeCartItemById(productId: number): any {
    // Find the index of the item with the specified product ID
    const index = state.cart.findIndex((item) => item.id === productId);

    if (index !== -1) {
      // Remove the item from the cart array
      state.cart.splice(index, 1);

      // Update the local storage with the modified cart
     // this.getCustomerLastOrder();
    this.checkmissingpromoitems();
    this.updateCartItems(state.cart);
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
      this.promotionswork("From Remove Item by Id");
      return true;
    } else {
      // If the item with the specified ID is not found, return false or handle accordingly
      return false;
    }
  }

  totalcart: any;
  totalcartqty: any;

  // Total amount
  public  cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100 ;
          }
          this.totalcart = (prev + price * curr.quantity) * this.Currency.price;
          this.totalcartqty = curr.quantity;
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }
  public cartTotalAmountPI(): Observable<number> {
    return this.cartItemsPI.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100 ;
          }
          this.totalcart = (prev + price * curr.quantity) * this.Currency.price;
          this.totalcartqty = curr.quantity;
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }

  promotiondata: any;
  aproducts: any;
  allproducts: any;
  mproducts: any;
  public promotionswork(call: any) {
    console.log("======= Promotion Call " + call + "=======");

    // Update cart total amount
    this.cartTotalAmount().subscribe((total) => {
      this.totalcart = total;

      console.log("🚀 > ProductService > this.cartTotalAmount > this.totalcart:", this.totalcart)
    });

    // Iterate through promotions
    this.promotiondata = JSON.parse(localStorage.getItem("promotiondata"));

    let maxBenefit = 0;
    let bestPromotion = null;
    let bestDiscountedTotal = this.totalcart;
    let bestDiscountAmount: any = 0;
    let bestDiscountPercentage: any = 0;

    this.promotiondata.forEach((promotion) => {
      if (promotion.isActive) {
        const currentDate = new Date();
        const startDate = new Date(promotion.date_Start);
        const endDate = new Date(promotion.date_End);

        if (currentDate >= startDate && currentDate <= endDate) {
          if (
            promotion.isApply_MinTotBil &&
            this.totalcart >= promotion.minTotBillAmount
          ) {
            const discountAmount =
              promotion.oDiscountAmount ||
              this.totalcart * (promotion.oDiscountPercent / 100);
            const discountedTotal = this.totalcart - discountAmount;

            if (discountAmount > maxBenefit) {
              maxBenefit = discountAmount;
              bestPromotion = promotion;
              bestDiscountedTotal = discountedTotal;
              bestDiscountAmount = discountAmount;
              bestDiscountPercentage = promotion.oDiscountPercent;
              console.log("No Discount Amount or Free Items");
              this.removeAllCartItemsPI();
            }
          } else if (promotion.isApply_MinQty) {
            this.cartItems.subscribe((cartItems) => {
              let totalQty = 0;
              let applicableDiscountAmount = 0;
              let applicableFreeQty = 0;
              let totalFreeItemPrice = 0;

              promotion.promotion_Items.forEach((item) => {
                if (item.isInput) {
                  const cartItem = cartItems.find(
                    (cartItem) => cartItem.id === item.itemNumber
                  );
                  if (cartItem) {
                    totalQty += cartItem.quantity;
                  }
                }
              });

              if (totalQty >= promotion.minQty) {
                // Calculate the multiplier for how many times the minQty fits into totalQty
                let qtyMultiplier = Math.floor(totalQty / promotion.minQty);

                if (promotion.oFreeQty > 0) {
                  promotion.promotion_Items.forEach((item) => {
                    if (!item.isInput) {
                      this.getProducts.subscribe((response) => {
                        this.aproducts = response;
                        this.mproducts = this.aproducts.filter(
                          (apiItem) => item.itemNumber === apiItem.id
                        );

                        console.log("======= FOC Item Here Start=======");
                        console.log(this.mproducts);

                        // Calculate the total price for free items
                        this.mproducts.forEach((product) => {
                          totalFreeItemPrice +=
                            product.price * promotion.oFreeQty * qtyMultiplier;
                        });
                        console.log(
                          "Total Discount for Free Items:",
                          totalFreeItemPrice
                        );
                        console.log("======= FOC Item Here Closed=======");

                        applicableFreeQty = promotion.oFreeQty * qtyMultiplier;
                      });
                    }
                  });
                } else if (promotion.oDiscountAmount > 0) {
                  applicableDiscountAmount =
                    promotion.oDiscountAmount * qtyMultiplier;
                }

                if (applicableDiscountAmount > maxBenefit) {
                  maxBenefit = applicableDiscountAmount;
                  bestPromotion = promotion;
                  bestDiscountedTotal =
                    this.totalcart - applicableDiscountAmount;
                  bestDiscountAmount = applicableDiscountAmount;
                  bestDiscountPercentage = 0;
                  console.log("No Discount Amount or Free Items");
                  this.removeAllCartItemsPI();
                } else if (totalFreeItemPrice > maxBenefit) {
                  maxBenefit = applicableFreeQty;
                  bestPromotion = promotion;
                  bestDiscountedTotal = this.totalcart;
                 
                  bestDiscountAmount = 0;
                  bestDiscountPercentage = 0;
                }
              } else {
                console.log("No Discount Amount or Free Items");
                this.removeAllCartItemsPI();
              }
            });
          }
        }
      }
    });

    if (bestPromotion) {
      console.log("======= Best Promotion Applied =======");
      console.log(bestPromotion);
      localStorage.setItem("totalafterdiscount", bestDiscountedTotal);
      
      localStorage.setItem("promotiondiscountvalue", bestDiscountAmount);
      localStorage.setItem("discountvalue", bestDiscountPercentage);

      if (bestPromotion.isApply_MinQty && bestPromotion.oFreeQty > 0) {
        this.applyFreeItems(bestPromotion, maxBenefit);
      }
    } else {
      localStorage.setItem("totalafterdiscount", this.totalcart);
      console.log("======= No Promotion se arha hai  totalafterdiscount======="+this.totalcart);
      localStorage.setItem("promotiondiscountvalue", "0");
      localStorage.setItem("discountvalue", "0");
    }
  }

  applyFreeItems(promotion, freeQty) {
    console.log("yaha Agya");
    promotion.promotion_Items.forEach((item) => {
      if (!item.isInput) {
        console.log("This is a free item: ", item);
        console.log("Free Item Number: ", item.itemNumber);
        console.log("Free Qty: ", freeQty);

        this.getProducts.subscribe((response) => {
          this.aproducts = response;
          this.mproducts = this.aproducts.filter(
            (apiItem) => item.itemNumber === apiItem.id
          );
          this.removeCartItemPI(this.mproducts[0]);
          if (this.mproducts.length > 0) {
            this.addToCartPI(this.mproducts[0], freeQty);
            console.log(
              "Free item promotion added item to cart: ",
              this.mproducts[0]
            );
          }
        });
      }
    });
  }

  removeAllCartItemsPI() {
    // Method to remove all promotional items from the cart
    this.cartItemsPI.forEach((item) => {
      console.log(item);
      this.removeCartItemPI(item[0]);
    });
  }


  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(
      map((product) =>
        product.filter((item: Product) => {
          if (!filter.length) return true;
          const Tags = filter.some((prev) => {
            // Match Tags
            if (item.tags) {
              if (item.tags.includes(prev)) {
                return prev;
              }
            }
          });
          return Tags;
        })
      )
    );
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    console.log("🚀 > ProductService > sortProducts > payload:", payload);

    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "sortingno") {
      return products.sort((a, b) => {
        if (a.sortingno < b.sortingno) {
          return -1;
        } else if (a.sortingno > b.sortingno) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = totalItems
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  
 
  ispromoitem: boolean = false;
  missinpromoproducts: Product[] = [];
  checkmissingpromoitems() {
    const promotiondata = JSON.parse(localStorage.getItem("promotiondata") || '[]');
    console.log("🚀 > CartComponent > checkmissingpromoitems > promotiondata", promotiondata);

    this.cartItems.subscribe((cartItems) => {
      promotiondata.forEach((promotion) => {
        if (promotion.isActive && promotion.isApply_MinQty) {
          let missingItems: string[] = [];

          promotion.promotion_Items.forEach((item) => {
            if (item.isInput) {
              // Check if the item is not present in cartItems
              const isMissing = !cartItems.some((cartItem) => cartItem.id === item.itemNumber);
              if (isMissing) {
                missingItems.push(item.itemNumber);
              } else {
                const index = missingItems.indexOf(item.itemNumber);
                if (index > -1) {
                  missingItems.splice(index, 1);
                }
              }
            }
          });

          this.ispromoitem = missingItems.length > 0;

          const allproducts = JSON.parse(localStorage.getItem("products") || '[]');
          const missingPromoItems = allproducts.filter((apiItem) => missingItems.includes(apiItem.id));

          // Update the BehaviorSubject with the latest missing promo items
          this.missingPromoItemsSubject.next(missingPromoItems);
          this.cartTotalAmount().subscribe((total) => {});
          console.log("🚀 > Product Service > checkmissingpromoitems > MissingPromoItems", missingPromoItems);
        }
      });
    });
  }
  
}
