<app-breadcrumb [title]="'Elements'" [breadcrumb]="'Product Tabs'"></app-breadcrumb>
<!-- Tab product start-->
<div class="title1 section-t-space">
  <h4>exclusive products</h4>
  <h2 class="title-inner1">special products</h2>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills" >
          <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
            <a ngbNavLink>
               {{ collection }}
            </a>
            <ng-template ngbNavContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of products | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one 
                      [product]="product" 
                      [currency]="productService?.Currency"
                      [thumbnail]="true"
                      [cartModal]="true">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>

        
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->