import { Component } from '@angular/core';
import {LoginService} from '../loginc/login.service';
import { Router } from '@angular/router';
import {GlobalobjectsService} from '../../globalobjects.service';
import { ToastrService } from 'ngx-toastr';
@Component({ 

  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  cnumber: any;
  countryName: string;
  msg: string; 
  constructor(private toastrService: ToastrService,private route:Router, public Loginservice:LoginService , public Globalobjects:GlobalobjectsService) { }


  selectCountry(country) {
    const flags = document.querySelectorAll('.flag');
    flags.forEach(flag => {
      flag.classList.remove('selected');
    });
  
    const selectedFlag = document.querySelector(`[alt="${country} flag"]`);
    selectedFlag.classList.add('selected');
  
    this.Globalobjects.countryName = country;
  }


  onSubmit() {
    if(this.Globalobjects.countryName == null){

      alert("Please Select Company");
    }
    else
    {
    this.Loginservice.getCustomerByNo(this.Globalobjects.customerNumber,this.Globalobjects.countryName).subscribe((response:any) => {
      console.log(response);
      if(response.apiStatus.isSuccess){

        this.Globalobjects.customerData = response.Data
        this.route.navigate(['/SetPassword']);
      }
      else{
        this.toastrService.error(response.apiStatus.Message,);
      }
    });
  }
}
}
