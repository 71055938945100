

<!-- Start Categories List -->


<div class="collection-collapse-block border-0" [class.open]="collapse">

    <h3 class="collapse-block-title" (click)="collapse = !collapse" >Category</h3>
    <hr *ngIf="catname!=''" >
    <label *ngIf="catname!=''">Filter By : ({{ catname }})</label>
        <hr *ngIf="catname!=''">
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
        
        <ul class="category-list">
          <li>
            <a [routerLink]="['/shop/productspecification']"
               [queryParams]="{ category: ''}">
              {{ capitalizeName('All') }}
            </a>
          </li>
          <li *ngFor="let category of filterbyCategory">
            <a [routerLink]="['/shop/productspecification']"
               [queryParams]="{ category: category.name }">
              {{ capitalizeName(category.name) }} ({{ category.count }})
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>




<!-- End Categories List -->    


