import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { LoginService } from 'src/app/mylogin/loginc/login.service';

@Component({
  selector: 'app-top-back-bar',
  templateUrl: './top-back-bar.component.html',
  styleUrls: ['./top-back-bar.component.scss']
})
export class TopBackBarComponent {
  isMobile: boolean;
  
  ngOnInit(): void {
    this.getCreditLimit();
    
  }
  constructor(private location: Location,
    private loginService: LoginService,

  ) {
    this.isMobile = window.innerWidth <= 768; // Simple mobile detection
    // Listen to resize events
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768;
    });
  }
  credit:any ;
  balance:any; 
  getCreditLimit() {

    const data = { 

      "countryList_Id": localStorage.getItem('country'),
      "customerNumber": localStorage.getItem('customerNumber')
      }
     
      this.loginService.getCustomerCredit(data).subscribe((response: any) => {      
      this.credit = response.data.value[0].creditLimitLCY;
      this.balance = response.data.value[0].balanceLCY;

     
    });
  }

  goBack() {
    this.location.back(); // Navigate back
  }

}
