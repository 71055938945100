<div
  *ngIf="PreLoader"
  class="bodyLoader"
  style="
    z-index: 9998;
    background-color: rgba(255, 255, 255, 0.7);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  "
>
  <div
    class="loader"
    style="
      position: fixed;
      top: 49%;
      left: 49%;
      transform: translate(-50%, -50%);
      z-index: 9999;
    "
  >
    <svg viewBox="0 0 80 80">
      <circle id="test" cx="40" cy="40" r="32"></circle>
    </svg>
  </div>

  <a
    class="dribbble"
    href="https://dribbble.com/shots/5878367-Loaders"
    target="_blank"
    ><img
      src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg"
      alt=""
  /></a>
</div>

<!-- <div class="loader triangle">
  <svg viewBox="0 0 86 80">
      <polygon points="43 8 79 72 7 72"></polygon>
  </svg>
</div>

<div class="loader">
  <svg viewBox="0 0 80 80">
      <rect x="8" y="8" width="64" height="64"></rect>
  </svg>
</div> -->

<!-- dribbble -->

<section class="login-page section-b-space d-flex align-items-center mt-3">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center">
          <img
            alt="logo"
            class="img-fluid"
            [src]="logoPath"
            width="250"
            height="200"
          />
        </div>
        <div class="theme-card">
          <form class="theme-form" (ngSubmit)="onSubmit()">
            <!-- <div class="form-group">
                            <div class="form-group">
                                <label for="country">Select a country:</label><br>
                                <img src="assets/images/flags/usa.png" alt="USA flag" width="50" height="40" class="flag" (click)="selectCountry('USA')">
                                <img src="assets/images/flags/canada.png" alt="Canada flag" width="50" height="40" class="flag ms-3" (click)="selectCountry('Canada')">

                            </div>
                        </div> -->
            <div>
              <label
                style="
                  color: black;
                  padding-top: 20px !important;
                  margin-right: 5px;
                "
                ><span style="padding: top 20px !important">
                  Sales Rep
                </span></label
              >

              <label class="switch">
                <input type="checkbox" [(ngModel)]="SaleRap" name="SaleRep" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="form-group mt-3">
              <label for="email">Customer Number/Email/Phone No.</label>
              <input
                type="text"
                class="form-control"
                id="email"
                placeholder="Customer Number/Email/Phone No."
                [(ngModel)]="userName"
                name="userName"
                required
              />
            </div>
            <div class="form-group">
              <label for="review">Password</label>
              <div class="input-group">
                <input
                  type="{{ showPassword ? 'text' : 'password' }}"
                  class="form-control"
                  id="review"
                  placeholder="Enter your password"
                  [(ngModel)]="userPass"
                  name="userPass"
                  required
                />
                <div
                  class="input-group-append"
                  (click)="togglePasswordVisibility()"
                >
                  <span class="input-group-text">
                    <img
                      width="24"
                      height="24"
                      style="margin: 8px 0px 7px 0px"
                      src="https://img.icons8.com/material-outlined/24/1A1A1A/visible--v1.png"
                      alt="visible--v1"
                    />
                  </span>
                </div>
              </div>
            </div>

            <label style="color: red">{{ msg }}</label>
            <button class="btn btn-solid" type="submit">Login</button
            ><br /><br />
            <!-- <p> Need an account? <a href="/register"> Register </a></p> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
