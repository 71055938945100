<div class="fixed-top">
  <div class="top-bar" *ngIf="isMobile">
    <a class="back-button" (click)="goBack()">
      <img width="30" height="30" src="https://img.icons8.com/?size=100&id=mN0cPZctq65n&format=png&color=ffffff"> </a>
    <div class="ms-auto text-end" *ngIf="credit">
      <ul>
        <li>
          <b>Credit Limit:
            <span>{{ credit | currency }} </span></b>
        </li>
        <br>
        <li>
          <a [routerLink]="['/pages/orderHistory']" [queryParams]="{ tab: 3 }" style="color: white;">
        <li>
          <b>Balance Outstanding:
            <span>{{ balance | currency }}
            </span></b>
        </li></a>
        </li>
      </ul>
    </div>
  </div>
</div>