<div class="icon-nav hoverclr">
  <ul>
    <!-- <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                </div>
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li> -->
    <!-- <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i></div>
      <div class="show-div setting"> 
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul>
      </div>
    </li> -->
    <li class="onhover-div mobile-cart text-center hoverclr">
      
      
      <span class="cart_qty_cls" style="border: 1px solid white;">{{ products.length }}</span>
      <a [routerLink]="['/shop/cart']" class=" mobile-Black-Color" >
        <picture>
          <source media="(max-width: 767px)" srcset="https://img.icons8.com/?size=100&id=NUcpUMIcTSZ3&format=png&color=000000">
          <source media="(min-width: 768px)" srcset="https://img.icons8.com/?size=100&id=NUcpUMIcTSZ3&format=png&color=ffffff">
          <img width="20" height="20" src="https://img.icons8.com/?size=100&id=NUcpUMIcTSZ3&format=png&color=ffffff" alt="checklist" style="vertical-align: bottom; padding-bottom: 3px;">
        </picture> Checkout</a> 
      <!-- <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="">
              <img class="me-3" [src]="Globalobjects.ImageURL+product?.images[0].src" [alt]="product?.images[0].alt">
            </a>
            <div class="media-body">
              <a [routerLink]="">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle abc" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart btn-danger"  style="color: white; ; background-color: var(--theme-default); padding: 10px;">CHECKOUT</a>
            <a [routerLink]="['/shop/shippingdetails']" class="checkout">checkout</a> 
          </div>
        </li>
      </ul> -->
    </li>
  </ul>
</div>
