import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  constructor() { }
  @ViewChild('TopHeader', { read: ElementRef }) TopHeader: ElementRef;
  height :string;

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.height = this.TopHeader.nativeElement.firstChild.offsetHeight+30 + 'px';
  }

}
