import { Component } from '@angular/core';
import { LoginService } from '../loginc/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pag500',
  templateUrl: './pag500.component.html',
  styleUrls: ['./pag500.component.scss']
})
export class Pag500Component {
  
  constructor(
    public Loginservice:LoginService,
    private router:Router
  ) { }

  ngOnInit(): void {
      this.Loginservice.getCompanyList().subscribe(response => {
        console.log("🚀 > Pag500Component > ngOnInit > this.Loginservice.getCompanyList > respo");
        this.router.navigate(['/index']);
      });
  }
  

}
