import { Component, OnInit, Input } from '@angular/core';
import { GlobalobjectsService } from 'src/app/globalobjects.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  logoPath: string="assets/images/placeholder-logo-2.png";
  settings: any;

  public today: number = Date.now();

  constructor(
    private GlobalObject: GlobalobjectsService,
  ) { }

  ngOnInit(): void {
  const settingString = localStorage.getItem('setting') as string;
    if( settingString != 'undefined' && settingString != null){
      this.settings = JSON.parse(localStorage.getItem('setting') as string);
    } 

    this.ReplaceLogo();
  }

ReplaceLogo() {
  const settingString = localStorage.getItem('CompanyDetails') as string;
    if( settingString != 'undefined' && settingString != null){
      const settings = JSON.parse(localStorage.getItem('CompanyDetails') as string);
      this.logoPath = this.GlobalObject.COMPANYIMAGEURL + settings.picture;

      console.log("🚀 > FooterOneComponent > ReplaceLogo > this.logoPath:", this.logoPath)
    } 
  }
}

