<section class="login-page section-b-space d-flex align-items-center mt-3">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center">
          <img
            alt="logo"
            class="img-fluid"
            [src]="logoPath"
            width="250"
            height="120"
          />
        </div>
        <div class="theme-card">
          <form class="theme-form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="form-group">
                <!-- <label for="country">Select Company:</label><br> -->

                <select
                  class="form-control"
                  (change)="selectCountry($event.target.value)"
                >
                  <option value="">Select Company</option>
                  <option
                    *ngFor="let company of companylist"
                    value="{{ company.id }}"
                  >
                    {{ company.countryName }}
                  </option>
                </select>
                <!-- <img src="assets/images/flags/usa.png" alt="3 flag" width="100" height="70" class="flag" (click)="selectCountry('3')">
                               
                               
                                <img src="assets/images/flags/canada.png" alt="2 flag" width="100" height="70" class="flag ms-3" (click)="selectCountry('2')">
                                 -->
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-solid mt-2" type="submit">Next</button
              ><br /><br />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
