import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-wishlistbutton',
    templateUrl: './wishlistbutton.component.html',
    styleUrls: ['./wishlistbutton.component.scss']
})
export class WishlistbuttonComponent {
    @Input() WishlistCount
    constructor () {}
}