<input
  type="text"
  [(ngModel)]="selectedOption"
  placeholder="Search and select..."
/>

<ul>
  <li *ngFor="let option of options" (click)="onOptionSelected(option)">
    {{ option.name }}
  </li>
</ul>