<section class="login-page section-b-space d-flex align-items-center mt-3">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center">
                    <img alt="logo" class="img-fluid" src="assets/images/icon/logo.png" width="250" height="200">
                </div>
                <div class="theme-card">
                    <form class="theme-form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" class="form-control" id="password" placeholder="Enter your password"
                                [(ngModel)]="userPassword" name="userPass" required>
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword">Confirm Password</label>
                            <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password"
                                [(ngModel)]="confirmPassword" name="confirmPassword" required>
                            <!-- Display message when passwords do not match -->
                            <p *ngIf="userPassword !== confirmPassword" class="text-danger">Passwords do not match</p>
                        </div>

                        <a [routerLink]="['/register']">
                            <button class="btn btn-solid">Back</button>
                        </a>
                        <!-- Disable the "Save" button if passwords do not match -->
                        <button class="btn btn-solid" type="submit" [disabled]="userPassword !== confirmPassword">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
